<template>
  <div class="integer-main">
    <van-list
      v-model="loading"
      :finished="finished"
      :class="{'list-null':tableData.length==0}"
      :finished-text='$t("MyIntegral.nomore")'
      @load="onLoad"
    >
      <van-pull-refresh v-model="isLoading" :disabled="refreshdisabled" class="myrefreh" @refresh="onRefresh">
        <div class="integer-head">
          <p class="title">{{ $t('MyIntegral.nowintegral') }}</p>
          <p class="points-text">{{ $store.getters.points }} <span>{{ $t('MyIntegral.fen') }}</span></p>
        </div>
        <van-tabs v-model="io" class="myTabs" @change="tabChange" line-width="0.96rem" color="#FF8039"
                  line-height="0.2rem" title-active-color="#FF8039" title-inactive-color="#303133"
        >
          <van-tab :title='$t("MyIntegral.all")' name="0">
            <div class="points-list">
              <div class="list-item" v-for="(item,index) in tableData" :key="index">
                <div class="details">
                  <p class="title">{{ item.desc }}</p>
                  <p class="points-num" :class="{'income':item.points<0}">{{ item.points }}</p>
                </div>
                <p class="time">{{ item.cdt }}</p>
              </div>
              <div class="nodata" v-if="tableData.length==0">
                <img src="@/assets/img/data-null.png">
              </div>
            </div>
          </van-tab>
          <van-tab :title='$t("MyIntegral.income")' name="1">
            <div class="points-list">
              <div class="list-item" v-for="(item,index) in tableData" :key="index">
                <div class="details">
                  <p class="title">{{ item.desc }}</p>
                  <p class="points-num" :class="{'income':item.points<0}">{{ item.points }}</p>
                </div>
                <p class="time">{{ item.cdt }}</p>
              </div>
              <div class="nodata" v-if="tableData.length==0">
                <img src="@/assets/img/data-null.png">
              </div>
            </div>
          </van-tab>
          <van-tab :title='$t("MyIntegral.expend")' name="-1">
            <div class="points-list">
              <div class="list-item" v-for="(item,index) in tableData" :key="index">
                <div class="details">
                  <p class="title">{{ item.desc }}</p>
                  <p class="points-num" :class="{'income':item.points<0}">{{ item.points }}</p>
                </div>
                <p class="time">{{ item.cdt }}</p>
              </div>
              <div class="nodata" v-if="tableData.length==0">
                <img src="@/assets/img/data-null.png">
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </van-pull-refresh>
    </van-list>
  </div>
</template>

<script>
import { pointsLog } from '@/api/shop'

export default {
  data() {
    return {
      isLoading: false,
      refreshdisabled: false,
      finished: false,
      loading: false,
      io: '0',
      pageNum: 1,
      tableData: []
    }
  },
  created() {
    document.title = this.$t('MyIntegral.name')
  },
  methods: {
    async getinfoData() {
      let datadd = {
        pageNum: this.pageNum,
        pageSize: 15,
        io: this.io
      }

      let result = await pointsLog(datadd)
      if (result.data.errorCode == 0) {
        if (this.pageNum == 1) {
          this.tableData = result.data.data.data
        } else {
          result.data.data.data.map(item => {
            this.tableData.push(item)
          })
        }
        if (result.data.data.data.length < datadd.pageSize) {
          this.finished = true
        }
      } else {
        if (this.pageNum == 1) {
          this.tableData = []
        } else {
          this.finished = true
        }
      }
    },
    tabChange() {
      this.loading = false
      this.pageNum = 1
      this.getinfoData()
    },
    onRefresh() {
      this.$router.go(0)
    },
    async onLoad() {
      if (!this.finished) {
        await this.getinfoData()
        this.loading = false
        this.pageNum++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.integer-main {
  background: #F5F6FA;
  min-height: 100%;

  .myrefreh {
    .van-pull-refresh__track {
      display: flex;
      flex-direction: column;
    }
  }

  .list-null {
    .van-list__finished-text {
      display: none;
    }
  }

  .integer-head {
    background: #fff;
    margin: 0 auto;
    padding: .6rem;
    padding-bottom: 0rem;
    height: 4.4rem;
    width: 13.8rem;
    background-image: url("~@/assets/img/integer-back.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 13.8rem 4.4rem;
    background-color: #fff;

    .title {
      font-size: .56rem;
      font-weight: 500;
      color: #FFFFFF;
      line-height: .8rem;
      margin-left: 1rem;
      margin-top: 1rem;
    }

    .points-text {
      padding-left: 1rem;
      font-size: 1.12rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 1.56rem;
      text-shadow: 0 .04rem .08rem rgba(232, 152, 3, 0.69);

      span {
        font-size: .52rem;
      }
    }
  }

  .myTabs {
    .van-tabs__wrap {
      height: 1.8rem;
    }

    .points-list {
      margin-top: .4rem;
      background: #fff;
      padding: 0 .6rem;
      // min-height: calc(100vh - 7.2rem);
      .nodata {
        height: calc(100vh - 7.2rem);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 8rem;
        }
      }

      .list-item {
        padding: .6rem 0;
        border-bottom: .04rem solid #F5F6FA;

        &:last-child {
          border: 0;
        }

        .details {
          display: flex;
          align-items: center;

          .title {
            font-size: .56rem;
            font-weight: 600;
            color: #303133;
            line-height: .8rem;
            flex: 1;
            // overflow: hidden;
          }

          .points-num {
            flex: auto 0 0;
            margin-left: .2rem;
            font-size: .72rem;
            font-weight: 500;
            color: #FF8039;
            line-height: 1rem;
          }

          .income {
            color: #C0C0C3;
          }
        }

        .time {
          font-size: .56rem;
          margin-top: .2rem;
          font-weight: 400;
          color: #838385;
          line-height: .8rem;
        }
      }
    }
  }
}

</style>
